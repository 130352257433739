.DropDown{
    border-radius: 5px;
    font-size: 15px;
    margin-left: 7px;
    /* padding: 5px; */
}
.Dropdown-menu{
    max-height: 150px;
}
.ControlClass{
    border-radius: 8px;
    background-color: #f2ebff;
    color: black;
}
.PlaceHolderClass{
}

.anchor{
    color: white;
    text-decoration: none;
}