@import url("https://fonts.googleapis.com/css?family=Secular+One");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");

.App {
  text-align: center;
  /* font-family: "Secular One"; */
  font-family: "Varela Round";

  flex-grow: 1;
  padding: 48px 0;
  gap: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  font-size: 30px;
  color: white;
  overflow: scroll;
}
.mainFont {
  font-family: "Secular One";
}

.clickable {
  cursor: pointer;
}

.App-header {
  font-family: "Varela Round";
  color: white;
}

.shadow2 {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.box {
  border-radius: 16px;
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.02);
  border-width: 3px;
  border-style: solid;
  padding: 32px;
  gap: 16px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transition: all 0.1s linear;
}

/* @media only screen and (orientation:portrait){
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
} */

.fade-in {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cell {
  height: 32px;
}

.overlay {
  background-color: green;
}

.neon {
  color: #fff;
  box-shadow: 0px 0px 20px 12px #0ff;
  margin-bottom: 12px;
}

.switch {
  opacity: 1 !important;
}

@keyframes pulsate {
  100% {
    /* Larger blur radius */
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }
  0% {
    /* Smaller blur radius */
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff, 0 0 10px #0fa,
      0 0 45px #0fa, 0 0 55px #0fa, 0 0 70px #0fa, 0 0 80px #0fa;
  }
}

.column-when-small {
  display: flex;
  flex-direction: row; /* Default layout */
}

@media (max-width: 1300px) {
  .column-when-small {
    flex-direction: column; /* Switch to column layout */
  }
}

.row-when-small {
  display: flex;
  flex-direction: column; /* Default layout */
}

@media (max-width: 1300px) {
  .row-when-small {
    flex-direction: row; /* Switch to column layout */
  }
}

.notification-container {
  position: relative;
}

.notification {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: slideUpFade 2s forwards;
}

@keyframes slideUpFade {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
}

.no-highlight {
  user-select: none;
  cursor: default;
}
