.icon {
  color: #ffffff;
  transition: all 0.3s ease;
}

.icon:hover {
  opacity: 0.8;
}

.lowlevelsetting {
  color: #ffffff;
  cursor: pointer;
  width: 80px;
}

.lowlevelsetting:hover {
  color: #b0b0b0;
}

.active {
  color: #89bd9e;
  border-color: "white";
}

.active:hover {
  color: #51906a;
  border-color: #51906a;
}

.poopy {
  color: #ea526f;
}

.poopy:hover {
  color: #c81939;
}

.normal {
  color: #b690f4;
}

.normal:hover {
  color: #9173c3;
}
